import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {userSignIn} from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const Otp = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authUser = useSelector(({auth}) => auth.authUser);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    // for(var i=0, len=localStorage.length; i<len; i++) {
    //   var key = localStorage.key(i);
    //   var value = localStorage[key];
    //   console.log(key + " => " + value);
    // }


    const onFinish = values => {
        console.log("finish",values)
        dispatch(userSignIn(values));
    };

    useEffect(() => {
        if (authUser !== null) {
            history.push('/');
        }
    }, [authUser]);

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg">

                        </div>
                        <div className="gx-app-logo-wid">
                            <h1><IntlMessages id="OTP Validation"/></h1>
                            <p><IntlMessages id="We sent you an email with  an otp code, please check your email"/></p>
                        </div>
                        <div className="gx-app-logo">
                            <img alt="example" src="/assets/images/coplogo.png"/>
                        </div>
                    </div>
                    <div className="gx-app-login-content">
                        <Form
                            initialValues={{ remember: true }}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0">

                            <h3>Enter your OTP</h3>

                            <Form.Item
                                // initialValue="demo#123"
                                rules= {[{required: true, message: 'Please input your Password!'}]}  name="otp">
                                <Input type="password" placeholder="Exampale : 123456"/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="gx-mb-0" htmlType="submit">
                                    <IntlMessages id="Submit"/>
                                </Button>
                                <Button type="info" className="gx-mb-0" htmlType="back">
                                    <IntlMessages id="Back to Login"/>
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <InfoView/>
                </div>
            </div>
        </div>
    );
};

export default Otp;
