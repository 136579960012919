import React, {useState, useEffect} from "react";
import {Form, Input, Card, Collapse, Col} from 'antd';
import jsonfile from './financialreport.json';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../util/CISUI';
import AdvancedSearch from '../../../util/AdvancedSearch';
import ActionButton from '../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../util/config";
import axios from 'axios';

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const ProductionReport = () => {
    const value = 0;
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.search,
    });

    const [datas, setDatas] = useState({
        total_income: '',
        total_expense: '',
        profit: '',
    });



    useEffect(() => {
        getData();
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.search
            }
        );
    }, []);

    const getData = () => {
        axios.get(Config.apiserver+jsonfile.urls.search, {headers: CisUI().headers})
            .then(res => {
                if(res.data.status === 1){
                    setDatas({
                        total_income: res.data.total_income,
                        total_expense: res.data.total_expense,
                        profit: res.data.profit,
                    });
                }
            })
    }

    const headers = {
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
        },
        income: {
            text: <IntlMessages id="Income"/>,
            sortable: true,
            filterable: true,
        },
        expense: {
            text: <IntlMessages id="Expense"/>,
            sortable: true,
            filterable: true,
        }
    }

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log(values)
        const data = {
            ...values,
            'from_date' : values['from_date'].format("YYYY-MM-DD"),
            'to_date' : values['to_date'].format("YYYY-MM-DD"),
        }
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.search+"/?"+qsup;
        setState({reqURL: newURL});
    };


    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />}>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
            <Col lg={12} md={12} sm={12} xs={12} style={{ margin:"0 auto"}}>
            <Card>
                <div style={{ textAlign:"center", color:"#000"}}>
                    <p><IntlMessages id="Total Income"/>: <strong>{datas.total_income}</strong> </p>
                    <p><IntlMessages id="Total Expense"/>: <strong>{datas.total_expense}</strong> </p>
                    <p><IntlMessages id="Total Profit"/>: <strong>{datas.profit}</strong> </p>
                </div>
            </Card>
            </Col>
        </>
    );
};

export default ProductionReport;