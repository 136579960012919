import React, { useState } from 'react';
import {Button, Card, Popover, message, Popconfirm, notification} from "antd";
import {Link, Redirect} from "react-router-dom";
import Config from "./config";
import axios from "axios";
import {CisUI} from "./CISUI";

const ActionButton = (id,prefix= {"edit":"edit","open":"open","delete":"delete"},endpoint="",mprefix="../") => {


    function confirm(e) {
        console.log(e);
        message.success('Click on Yes');
        let url = Config.apiserver+prefix.delete+`/${id}`;
        // <Redirect to={"../"+prefix.delete+`/${id}`}></Redirect>
        console.log(url);

        axios.delete(url, CisUI().HeaderRequest)
            .then(res=>{
                // console.log(res.data.status);
                // if(res.data.status === 0){
                //     notification["warning"]({
                //         message: 'Warning.',
                //         description: res.data.msg,
                //     });
                // } else {
                //     const isNotId = type => type.id !== id;
                //     const update = depositLists.filter(isNotId);
                //     setDepositLists(update);
                //     notification["success"]({
                //         message: 'Success.',
                //         description: res.data.msg,
                //     });
                // }
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
            })
            .catch((error)=>{
                console.log(error)
                notification.error({
                    message: 'Error.',
                    type: 'error',
                    description: "Failed to delete."
                });
            })
    }

    function cancel(e) {
        console.log(e);
        message.error("Data is not deleted.");
    }


    const text = <span>Actions</span>;
    const content = (
        <div>
            {/*<p>*/}
            {/*    <Link to={prefix.view+`/${id}`}>*/}
            {/*        <button className="btn btn-primary btn-circle">*/}
            {/*            <i className="fas fa-folder-open"/>*/}
            {/*        </button> Open*/}
            {/*    </Link>*/}
            {/*</p>*/}
            <p>
                <Link to={mprefix+prefix.edit+`/${id}`}>
                    <button className="btn btn-primary btn-circle">
                        <i className="fas fa-pencil-alt"/>
                    </button> Edit
                </Link>
            </p>
            <p>
                <Link to="#">
                    <Popconfirm title="Are you sure you want to delete this?" onConfirm={confirm} onCancel={cancel} okText="Yes"
                                cancelText="No">
                            <button className="btn btn-danger btn-circle">
                                <i className="fas fa-trash"/>
                            </button> Delete
                    </Popconfirm>
                </Link>
            </p>
        </div>
    );

    return (
        <Popover placement="leftBottom" title={text} content={content} trigger="click">
            <button className="btn btn-primary btn-circle"><i className="fas fa-ellipsis-h"></i></button>
        </Popover>
    );
}

export default ActionButton;