import React, {useState, useEffect} from "react";
import {Input, Select} from 'antd';
// import axios from "axios";
import Config from "./config";
import axios from 'util/Api'


const { Option } = Select;
const {TextArea} = Input;


const RemoteJson = (endpoint="") => {
    let respData = [];
    let dataValue = [];

    const [companyLists, setCompanyLists] = useState([]);

    // const [state, setState] = useState([]);

    useEffect(() => {
        getAllAccounts();
    }, []);

    const token = localStorage.getItem('token');

    const getAllAccounts = async () => {
        axios.defaults.headers.common['Authorization'] = token;
        return await axios.get(Config.apiserver+endpoint)
            .then((res) => {
                //console.log(res.data);
                if(res.data.status === 1) {
                    setCompanyLists(res.data.data);
                    // console.log("userData: ",res.data.data);
                    // setState(res.data.data)
                }
            })
            .catch((error) => {
                console.log(error);
                setCompanyLists([]);
                // setState([]);
            });
    };

    return companyLists;
    // return state;

}

export default RemoteJson;