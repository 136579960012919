import React, {useState, useEffect} from "react";
import {Input, Select} from 'antd';
import axios from 'util/Api'


const { Option } = Select;
const {TextArea} = Input;


const SelectData = (endpoint) => {
    let respData = [];
    let dataValue = [];

    const [companyLists, setCompanyLists] = useState([]);

    useEffect(() => {
        getAllAccounts();
    }, []);

    const token = localStorage.getItem('token');

    const getAllAccounts = async () => {
        return await axios.get(endpoint)
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    setCompanyLists(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return companyLists;

}

export default SelectData;