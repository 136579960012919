import React from "react";
import {Route, Switch} from "react-router-dom";

import FlockList from "../../containers/yotta/flock/FlockList";
import NewFlock from "../../containers/yotta/flock/NewFlock";

const Flock = ({match}) => (
    <Switch>
        <Route path={`${match.url}/listflock`} component={FlockList}/>
        <Route path={`${match.url}/newflock`} component={NewFlock}/>
        <Route path={`${match.url}/flockedit/:id`} component={NewFlock}/>
    </Switch>
);

export default Flock;