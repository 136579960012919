import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Card, Table, Collapse, notification} from 'antd';
import jsonfile from './production_treatment.json'
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../util/CISUI';
import AdvancedSearch from '../../../util/AdvancedSearch';
import ActionButton from '../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../util/config";
import axios from "axios";

const Search = Input.Search;
const Panel = Collapse.Panel;

const ProductionTreatmentList = () => {
    let locate = useParams();
    let production_id = parseInt(locate.id);

    const history = useHistory();
    const [state,setState] = useState({
        reqURL:Config.apiserver+jsonfile.urls.list+"/"+production_id,
        filtervalue : ''
    });
    useEffect(() => {
        setState({
            reqURL: Config.apiserver+jsonfile.urls.list+"/"+production_id
        })
    }, []);

    const handleDelete = (id) => {
        axios.delete(Config.apiserver+jsonfile.urls.delete+`/${id}`, CisUI().HeaderRequest)
            .then(res=>{
                if(res.data.status === 1) {
                    setState({
                        reqURL: Config.apiserver+jsonfile.urls.list+"/"+production_id
                    });
                    notification.success({
                        message: 'Success',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        treatment_given: {
            text: <IntlMessages id="Treatment Given"/>,
            sortable: true,
            filterable: true,
        },
        description: {
            text: <IntlMessages id="Reason"/>,
            sortable: true,
            filterable: true,
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {
                        <>
                            <Link to={`../../../production/productionopen/`+production_id+`/productiontreatmentedit/`+row.id} style={{marginRight:"5px"}}><i className="icon icon-edit"/></Link>
                            <Link to="#" className={row.id} onClick={() => handleDelete(row.id)}><i className="icon icon-trash"/></Link>
                        </>
                    }
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;



    const getExtra = (
        <>
            {CisUI().listAction("../../../production/productionopen/"+production_id+"/newproductiontreatment")}
        </>
    );

    return (
        <>
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
                <Col lg={24} md={24} sm={24} xs={24}>
                    <div style={{backgroundColor:"#f5f5f5", marginTop:"10px"}}>
                        <ul style={{ marginLeft:"50px", padding:"15px 5px 15px 0px"}}>
                            <li><IntlMessages id="Add a treatment record by clicking on"/> <i className="icon icon-add" /> <IntlMessages id="next to the 'Feed' title."/></li>
                            <li><IntlMessages id="You can add multiple records for this batch/flock, for example in the event you give different types of feeds or give feed at different times of the day"/></li>
                        </ul>
                    </div>
                </Col>
            </Card>
        </>
    );
};

export default ProductionTreatmentList;