import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button ,Card, Collapse, Table} from 'antd';
import jsonfile from './production.json';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import 'react-smart-data-table/dist/react-smart-data-table.css';
import RemoteJson from "../../../util/gerremotedata";
import ProductionFeedList from "../production_feed/ProductionFeedList";
import ProductionVaccineList from "../production_vaccination/ProductionVaccineList";
import ProductionMortalityList from "../production_mortality/ProductionMortalityList";
import ProductionTreatmentList from "../production_treatment/ProductionTreatmentList";
import ProductionWeightList from "../production_weight/ProductionWeightList";
import {CisUI} from "../../../util/CISUI";
import ProductionEggCollectionList from "../production_egg_collection/ProductionEggCollectionList";
import ProductionEggWeightList from "../production_egg_weight/ProductionEggWeightList";
import IntlMessages from "../../../util/IntlMessages";

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;


const ProductionList = (props) => {

    const userID = props.match.params.id;
    let userData = RemoteJson(jsonfile.urls.view+"/"+userID);

    // console.log(userData);

    const history = useHistory();

    const print = () => {
        window.print()
    }

    const getExtra = (
        <>
            <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                className="fas fa-print"/></button>
            <button type="info" className="btn btn-export-print btn-circle-md" title="Go Back" onClick={() => history.goBack()} ><i className="fas fa-times-circle"/></button>
        </>
    );

    return (
        <>
            <Card extra={getExtra}>
                <h2><IntlMessages id="Production"/></h2>
                <p>Mor on {userData.production_date} for flock batch no: {userData.batch_no}</p>
                <Row style={{ marginTop:"20px"}}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <p><strong><IntlMessages id="Flock Batch No"/>: {userData.batch_no}</strong></p>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <p><strong><IntlMessages id="House/Shed/Coop"/>: {userData.house}</strong></p>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <p><strong><IntlMessages id="Strain/Breed"/>: {userData.strain}</strong></p>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <p><strong><IntlMessages id="Initial Number"/>: {userData.initial_stock}</strong></p>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <p><strong><IntlMessages id="Current Number"/>: {userData.current_stock}</strong></p>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <p><strong><IntlMessages id="Purpose"/>: {userData.purpose}</strong></p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div style={{backgroundColor:"#f5f5f5", marginTop:"10px"}}>
                            <ul style={{ marginLeft:"50px", padding:"15px 5px 15px 0px"}}>
                                <li><IntlMessages id="To ensure accuracy of generated reports, only add records for the selected batch, for the selected date."/></li>
                                <li><IntlMessages id="You can add multiple records for this batch/flock for the selected date."/></li>
                                <li><IntlMessages id="To add production records for a different batch, please add another production record and choose the batch you would like to create the records for."/></li>
                                <li><IntlMessages id="To add production records on a different date, please add another production record and choose the batch you would like to create the records for."/></li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                {/*<Row>*/}
                {/*    <Col lg={24} md={24} sm={24} xs={24}>*/}

                {/*    </Col>*/}
                {/*</Row>*/}
            </Card>
            {
                userData.purpose === 'Eggs' && (
                    <>
                        <ProductionEggCollectionList productionID={userData.id} />
                        <ProductionEggWeightList productionID={userData.id} />
                    </>
                )
            }

            <ProductionFeedList productionID={userData.id} />
            <ProductionVaccineList productionID={userData.id} />
            <ProductionMortalityList productionID={userData.id} />
            <ProductionTreatmentList productionID={userData.id} />
            <ProductionWeightList productionID={userData.id} />

        </>
    );
};

export default ProductionList;