import React from "react";
import {Route, Switch} from "react-router-dom";

import FlockType from "../../containers/yotta/settings/flock_type/FlockTypeList";
import NewFlockType from "../../containers/yotta/settings/flock_type/NewFlockType";
import FeedType from "../../containers/yotta/settings/feed_type/FeedTypeList";
import NewFeedType from "../../containers/yotta/settings/feed_type/NewFeedType";
import MedicationList from "../../containers/yotta/settings/medication_vaccines/MedicationList";
import NewMedication from "../../containers/yotta/settings/medication_vaccines/NewMedication";
import IncomeCategoryList from "../../containers/yotta/settings/income_category/IncomeCategoryList";
import NewIncomeCategory from "../../containers/yotta/settings/income_category/NewIncomeCategory";
import ExpenseCategoryList from "../../containers/yotta/settings/expense_category/ExpenseCategoryList";
import NewExpenseCategory from "../../containers/yotta/settings/expense_category/NewExpenseCategory";
import ProfileSettings from "../../containers/yotta/settings/profile/ProfileSettings";

const Settings = ({match}) => (
    <Switch>
        <Route path={`${match.url}/flocktype`} component={FlockType}/>
        <Route path={`${match.url}/newflocktype`} component={NewFlockType}/>
        <Route path={`${match.url}/flocktypeedit/:id`} component={NewFlockType}/>
        <Route path={`${match.url}/feedtype`} component={FeedType}/>
        <Route path={`${match.url}/newfeedtype`} component={NewFeedType}/>
        <Route path={`${match.url}/feedtypeedit/:id`} component={NewFeedType}/>
        <Route path={`${match.url}/medicationvaccine`} component={MedicationList}/>
        <Route path={`${match.url}/newmedicationvaccine`} component={NewMedication}/>
        <Route path={`${match.url}/medicationvaccineedit/:id`} component={NewMedication}/>
        <Route path={`${match.url}/incomecategories`} component={IncomeCategoryList}/>
        <Route path={`${match.url}/newincomecategories`} component={NewIncomeCategory}/>
        <Route path={`${match.url}/incomecategoriesedit/:id`} component={NewIncomeCategory}/>
        <Route path={`${match.url}/expensecategories`} component={ExpenseCategoryList}/>
        <Route path={`${match.url}/newexpensecategories`} component={NewExpenseCategory}/>
        <Route path={`${match.url}/expensecategoriesedit/:id`} component={NewExpenseCategory}/>
        {/*Profile*/}
        <Route path={`${match.url}/profilesettings`} component={ProfileSettings}/>

    </Switch>
);

export default Settings;