import {Form, Card,notification} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './flock.json'
import {CisUI} from '../../../util/CISUI'
import InputFields from '../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";
import IntlMessages from '../../../util/IntlMessages';

const NewFlock = (props) => {

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        const data = {
            ...values,
            'date_in' : values['date_in'].format("YYYY-MM-DD"),
            'retirement_date' : values['retirement_date'].format("YYYY-MM-DD")
        };
        axios.post(Config.apiserver + endpoint, data, {headers: CisUI().headers})
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "warning",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                // setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                // setLoading({loading:false});
                //setResponse([]);
            });
    };


    //console.log("UserData : "+userData);

    return (
        <Card title={<IntlMessages id={Title} />}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {InputFields(jsonfile.input,userData,isEdit)}
            </Form>


        </Card>
    );
};

export default NewFlock;