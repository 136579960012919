import React from "react";
import {Route, Switch} from "react-router-dom";

import IncomeList from "../../containers/yotta/income/IncomeList";
import NewIncome from "../../containers/yotta/income/NewIncome";

const Income = ({match}) => (
    <Switch>
        <Route path={`${match.url}/listincome`} component={IncomeList}/>
        <Route path={`${match.url}/newincome`} component={NewIncome}/>
        <Route path={`${match.url}/incomeedit/:id`} component={NewIncome}/>
    </Switch>
);

export default Income;