import React from "react";
import {Route, Switch} from "react-router-dom";

import ProductionReport from "../../containers/yotta/report/ProductionReport";
import FinancialReport from "../../containers/yotta/report/FinancialReport";

const Reports = ({match}) => (
    <Switch>
        <Route path={`${match.url}/productionreport`} component={ProductionReport}/>
        <Route path={`${match.url}/financialreport`} component={FinancialReport}/>
    </Switch>
);

export default Reports;