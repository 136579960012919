import React, {useState, useEffect} from "react";
import { Form, Input,Card, Collapse} from 'antd';
import jsonfile from './productionreport.json';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../util/CISUI';
import AdvancedSearch from '../../../util/AdvancedSearch';
import ActionButton from '../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../util/config";

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const ProductionReport = () => {
    const value = 0;
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.search,
        filtervalue : ''
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.search
            }
        );
        // console.log(state.reqURL);
    }, []);

    const rate = (row) => {
        let initial_stock = parseInt(row.initial_stock);
        let mortality_number = parseInt(row.mortality_number);
        let rate = (mortality_number * 100) / initial_stock;
        let mortality_rate = rate.toFixed(2);
        if(isNaN(mortality_rate)){
            return 0+'%';
        } else {
            return mortality_rate+'%';
        }
    }

    const headers = {
        id: {
            text: <IntlMessages id="SL NO"/>,
            sortable: true,
            filterable: true,
        },
        flock_name: {
            text: <IntlMessages id="Flock Name"/>,
            sortable: true,
            filterable: true,
        },
        batch_no: {
            text: <IntlMessages id="Batch No"/>,
            sortable: true,
            filterable: true,
        },
        initial_stock: {
            text: <IntlMessages id="Initial Stock"/>,
            sortable: true,
            filterable: true,
        },
        current_stock: {
            text: <IntlMessages id="Current Stock"/>,
            sortable: true,
            filterable: true,
        },
        house: {
            text: <IntlMessages id="House/Shed/Coop"/>,
            sortable: true,
            filterable: true,
        },
        strain: {
            text: <IntlMessages id="Breed/Strain"/>,
            sortable: true,
            filterable: true,
        },
        purpose: {
            text: <IntlMessages id="Purpose"/>,
            sortable: true,
            filterable: true,
        },
        mortality_number: {
            text: <IntlMessages id="Mortality_rate"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                rate(row)
            )
        }
    }

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        console.log(values)
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.search+"/?"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />} key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default ProductionReport;