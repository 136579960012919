import React from "react";
import {Route, Switch} from "react-router-dom";

import ProductionList from "../../containers/yotta/production/ProductionList";
import NewProduction from "../../containers/yotta/production/NewProduction";
import ProductionDetails from "../../containers/yotta/production/ProductionDetails";
import NewProductionEggCollection from "../../containers/yotta/production_egg_collection/NewProductionEggCollection";
import NewProductionEggWeight from "../../containers/yotta/production_egg_weight/NewProductionEggWeight";
import NewProductionFeed from "../../containers/yotta/production_feed/NewProductionFeed";
import NewProductionVaccine from "../../containers/yotta/production_vaccination/NewProductionVaccine";
import NewProductionMortality from "../../containers/yotta/production_mortality/NewProductionMortality";
import NewProductionTreatment from "../../containers/yotta/production_treatment/NewProductionTreatment";
import NewProductionWeight from "../../containers/yotta/production_weight/NewProductionWeight";

const Production = ({match}) => (
    <Switch>
        <Route path={`${match.url}/listproduction`} component={ProductionList}/>
        <Route path={`${match.url}/newproduction`} component={NewProduction}/>
        <Route exact path={`${match.url}/productionopen/:production_id/newproductioneggcollection`} component={NewProductionEggCollection}/>
        <Route exact path={`${match.url}/productionopen/:production_id/productioneggcollectionedit/:collection_id`} component={NewProductionEggCollection}/>
        <Route exact path={`${match.url}/productionopen/:production_id/newproductioneggweight`} component={NewProductionEggWeight}/>
        <Route exact path={`${match.url}/productionopen/:production_id/productioneggweightedit/:egg_weight_id`} component={NewProductionEggWeight}/>
        <Route exact path={`${match.url}/productionopen/:production_id/newproductionfeed`} component={NewProductionFeed}/>
        <Route exact path={`${match.url}/productionopen/:production_id/productionfeededit/:feed_id`} component={NewProductionFeed}/>
        <Route exact path={`${match.url}/productionopen/:production_id/newproductionvaccine`} component={NewProductionVaccine}/>
        <Route exact path={`${match.url}/productionopen/:production_id/productionvaccineedit/:vaccine_id`} component={NewProductionVaccine}/>
        <Route exact path={`${match.url}/productionopen/:production_id/newproductionmortality`} component={NewProductionMortality}/>
        <Route exact path={`${match.url}/productionopen/:production_id/productionmortalityedit/:mortality_id`} component={NewProductionMortality}/>
        <Route exact path={`${match.url}/productionopen/:production_id/newproductiontreatment`} component={NewProductionTreatment}/>
        <Route exact path={`${match.url}/productionopen/:production_id/productiontreatmentedit/:treatment_id`} component={NewProductionTreatment}/>
        <Route exact path={`${match.url}/productionopen/:production_id/newproductionweight`} component={NewProductionWeight}/>
        <Route exact path={`${match.url}/productionopen/:production_id/productionweightedit/:weight_id`} component={NewProductionWeight}/>
        <Route path={`${match.url}/productionopen/:id`} component={ProductionDetails}/>

    </Switch>
);

export default Production;