import React, {useState} from "react";
import {Form, Row, Col, Input, Button, Card, Select, Checkbox, Collapse, Tree, notification} from 'antd';
import jsonfile from './user_roles.json'
import actions from './actions.json'
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI'
import InputFieldsOnly from '../../../../util/InputOnly'
import RemoteData from '../../../../util/RemoteData'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import axios from "../../../../util/Api";

import "./basic.less";
import RemoteJson from "../../../../util/RemoteData";
import Config from "../../../../util/config";
import IntlMessages from "../../../../util/IntlMessages";

const { Option } = Select;
const {TextArea} = Input;

const TreeNode = Tree.TreeNode;
const CheckboxGroup = Checkbox.Group;

const Panel = Collapse.Panel;

const formItemLayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


const New = (props) => {

    const [loading, setLoading] = useState([]);
    const [checked, setChecked] = useState([]);
    const [selected, setSelected] = useState({});
    const [state, setState] = useState(false);
    const [checkedKeys, setCheckedKeys] = useState({});


    const callback = (key) => {
        console.log(key);
    };

    const history = useHistory();
    const handleChange=(id)=> {
        const selectedCheckboxes = state.selectedCheckboxes;
    }

    const reqID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;


    if(reqID > 0) {
        isEdit = 1;
    }

    let endpoint = ""+jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    let sRoles = [];

    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+reqID);
        // console.log(typeof userData);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+reqID;
        redirectto = "../../"+jsonfile.urls.list;
       // setState(userData.roles);
        sRoles = userData.roles;
    }

   //console.log("USERData :" +userData);

    const onExpand = (expandedKeys) => {
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    const onCheck = (checkedKeys,info) => {
        //console.log('PSelected', userData.roles);
        //console.log('onCheck', checkedKeys);
        //console.log('checkedD', checked);
        //console.log('node: ', info);
        let ck = [];
        let selected = checked;

        if(isEdit === 1) {
            if(checked.length > 0) {
                //selected = sRoles;
            }
            else {
                selected = sRoles;
            }
            //console.log(sRoles);
        }

        //console.log(selected);

        if(info['checked'] == true) {
            let nk = [];
            //console.log("selected : ",selected);
            checkedKeys.map((items)=>{
                if(selected.indexOf(items) === -1)
                {
                    nk.push(items);
                }
            });
            console.log("NewSelected : ",nk);
            ck = [...selected,...nk];
        }
        else {
            let ckup = selected;
            console.log("BeforeFilter : ", ckup);
            //console.log('node: ', info);
            if(info['node']['children'] !== undefined) {
                const childkey = info['node']['children'];
                ckup = ckup.filter((items) => items !== info['node']['key']);
                //console.log("MainChild : ", ckup);
                childkey.map((item,index) => {
                    //console.log(item["key"]);
                    ckup = ckup.filter((items) => items !== item["key"]);
                    if(item['children'] !== undefined) {
                        const childkey2 = item['children'];
                        childkey2.map((item3,index3) => {
                            //console.log(item3['key']);
                            ckup = ckup.filter((items2) => items2 !== item3["key"]);
                            if(item3['children'] !== undefined) {
                                const childkey3 = item3['children'];
                                childkey3.map((item4,index4) => {
                                    ckup = ckup.filter((items4) => items4 !== item4["key"]);
                                });
                            }
                        });
                    }
                });
            }
            else {
                ckup = ckup.filter((item) => item !== info['node']['key']);
            }
            ck = ckup;
        }
        //console.log('AllSelected', ck);

        setChecked(ck);

        console.log("CheckedArray :", ck);
    };

    const onSelect = (selectedKeys, info) => {
        console.log('onSelect', info);
        setState({selectedKeys});
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {

        //const endpoint = "user/roleadd";

        //console.log('Received values of form: ', values);
        //console.log('Checked Key: ', checked);
        //console.log('Chekced2 Key: ', state.selectedCheckboxes);

        if(isEdit === 1) {
            if(checked.length > 0) {
                values["roles"] = checked;
            }
            else {
                values["roles"] = sRoles;
            }
        }
        else {
            values["roles"] = checked;
        }

         // console.log("send : "+Object.entries(values));
         console.log(values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log("ResponseData "+res);
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }

            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                notification.warning({
                    message: 'Alert',
                    type : "warning",
                    description:"Request failed, please check your internet connection"
                });
                document.getElementById("loader").style.display = "none";
                //setResponse([]);
            });

    };

    const getAllModule = RemoteData("moduletree");
    //console.log(getAllModule);


    const renderTreeNodes = (data) => {
        return data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} />;
        });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
                isEdit === 1
                    ? CisUI().addAction(redirectto)
                    : CisUI().addAction(redirectto)
            }>
            {CisUI().showLoading}

            <Form
                form={form}
                {...formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    {InputFieldsOnly(jsonfile.input,userData,isEdit, "", redirectto)}
                </Row>
                <Row gutter={24}>
                    {
                        getAllModule.map((list) => (
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'left'}}>
                                <Card className="card_primary" title={list.title}>
                                    <Tree
                                        checkable
                                        expanded="true"
                                        defaultExpandAll={true}
                                        defaultExpandedKeys={[list.key]}
                                        autoExpandParent={expand}
                                        onSelect={onSelect}
                                        showLine={false}
                                        defaultCheckedKeys={userData.roles}
                                        onCheck={onCheck}>
                                        <TreeNode title={list.title} key={`${list.key}`}>
                                            {
                                                list.children.map((item) => (
                                                    <TreeNode title={item.title} key={`${list.key}_${item.key}`}>
                                                        {
                                                            actions.map((action) => (
                                                                <TreeNode className="actionNode" title={action.name} key={`act_${list.key}_${item.key}_${action.id}`} />
                                                            ))
                                                        }
                                                    </TreeNode>
                                                ))
                                            }
                                        </TreeNode>
                                    </Tree>
                                </Card>
                            </Col>
                        ))

                    }


                    {/*{*/}
                    {/*    getAllModule.map((list) => (*/}
                    {/*        <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'left'}}>*/}
                    {/*            <Card className="card_primary" title={list.title}>*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-sm-12" id={"tree-"+list.key} style={{padding: '20px;'}}>*/}
                    {/*                        <ul className="treeview">*/}
                    {/*                            <li>*/}
                    {/*                                <label className="custom-unchecked"><input type="checkbox" name={`${list.key}`} id={`${list.key}`} value="1" /> {list.title}</label>*/}
                    {/*                                <ul>*/}
                    {/*                                    {*/}
                    {/*                                        list.children.map((item) => (*/}
                    {/*                                            <li>*/}
                    {/*                                                <label className="custom-unchecked"> <input type="checkbox" name={`${list.key}_${item.key}`} id={`${list.key}_${item.key}`} value="1" /> {item.title}</label>*/}

                    {/*                                                <ul className="activity">*/}
                    {/*                                                    {*/}
                    {/*                                                        actions.map((action) => (*/}
                    {/*                                                            <li>*/}
                    {/*                                                                <label className="custom-unchecked"><input type="checkbox" onChange={() => handleChange(`act_${list.key}_${item.key}_${action.id}`)} name={`act_${list.key}_${item.key}_${action.id}`} value="1" id={`act_${list.key}_${item.key}_${action.id}`} /> {action.name}</label>*/}
                    {/*                                                            </li>*/}
                    {/*                                                        ))*/}
                    {/*                                                    }*/}

                    {/*                                                </ul>*/}

                    {/*                                            </li>*/}
                    {/*                                        ))*/}
                    {/*                                    }*/}
                    {/*                                </ul>*/}

                    {/*                            </li>*/}

                    {/*                        </ul>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*            </Card>*/}
                    {/*        </Col>*/}
                    {/*    ))*/}
                    {/*}*/}
                </Row>

                <Row gutter={24} className="bottomFixed">
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>


            </Form>

        </Card>
    );
};

export default New;