import React from "react";
import {Route, Switch} from "react-router-dom";

import ExpenseList from "../../containers/yotta/expense/ExpenseList";
import NewExpense from "../../containers/yotta/expense/NewExpense";

const Expense = ({match}) => (
    <Switch>
        <Route path={`${match.url}/listexpense`} component={ExpenseList}/>
        <Route path={`${match.url}/newexpense`} component={NewExpense}/>
        <Route path={`${match.url}/expenseedit/:id`} component={NewExpense}/>
    </Switch>
);

export default Expense;