import React, {Component} from 'react';

const StaticSelectedData = (name) => {
    let data = [];
    if(name === "purpose") {
        data = [
            {
                id:"Eggs",
                name: "Eggs (Layers)"
            },
            {
                id:"Meat",
                name:"Meat (Broilers)"
            },
            {
                id:"Meat",
                name:"Meat (Spent Layers)"
            },
            {
                id:"Chick Sale",
                name:"Chick Sale (Hatchery)"
            },
            {
                id:"Breeding",
                name:"Breeding"
            },
            {
                id:"Other",
                name:"Other"
            },
        ];
    } else if(name === "type"){
        data = [
            {
                id: "Vaccine",
                name: "Vaccine"
            },
            {
                id: "Medication",
                name: "Medication"
            },
            {
                id: "Vitamin",
                name: "Vitamin"
            },
            {
                id: "Probiotic",
                name: "Probiotic"
            },
            {
                id: "Disinfectant",
                name: "Disinfectant"
            }
        ];
    } else if(name === "source") {
        data = [
            {
                id: "Hatchery",
                name: "Hatchery"
            },
            {
                id: "Breeder",
                name: "Breeder"
            },
            {
                id: "Incubator",
                name: "Incubator(Own)"
            },
            {
                id: "Hen(Hen Hatching Eggs)",
                name: "Hen(Hen Hatching Eggs)"
            }
        ];
    } else if(name === "culling_mortality") {
        data = [
            {
                id: "Culling",
                name: "Culling"
            },
            {
                id: "Mortality",
                name: "Mortality"
            }
        ];
    } else if(name === "egg_color") {
        data = [
            {
                id: "White",
                name: "White"
            },
            {
                id: "Brown",
                name: "Brown"
            },
            {
                id: "Blue",
                name: "Blue"
            },
            {
                id: "Colored",
                name: "Colored"
            },
            {
                id: "Green",
                name: "Green"
            }
        ];
    } else if(name === "egg_size") {
        data = [
            // {
            //     id: "Peewee",
            //     name: "Peewee"
            // },
            {
                id: "Small",
                name: "Small"
            },
            {
                id: "Medium",
                name: "Medium"
            },
            {
                id: "Large",
                name: "Large"
            },
            {
                id: "Unsorted",
                name: "Unsorted"
            },
            // {
            //     id: "Extra Large",
            //     name: "Extra Large"
            // },
            // {
            //     id: "Jumbo",
            //     name: "Jumbo"
            // },
            // {
            //     id: "Super Jumbo",
            //     name: "Super Jumbo"
            // }
        ];
    }

    return data;
}

export default StaticSelectedData;