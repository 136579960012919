import React, {useEffect, useState} from "react";
import {Breadcrumb, Col, Input, Layout, Row, Form, notification, Card, Select} from "antd";
import {Button} from "antd";
import {CisUI} from "../../../../util/CISUI";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {Option} from "antd/es/mentions";
import axios from "axios";
import Config from "../../../../util/config";
import IntlMessages from "../../../../util/IntlMessages";


const ProfileSettings = () => {
	const [image, setImage] = useState("");
	const [source, setSource] = useState("");
	// const userID = CisUI().getLocalUserID();
	const [countries, setCountry] = useState([]);
	const [userData, setUserData] = useState([]);
	const [form] = Form.useForm();
	const getUserData = (() => {
		axios.get(Config.apiserver + "myaccount", CisUI().HeaderRequest)
			.then(function (response) {
				console.log("response", response)
				if (response.data.status === 1) {
					console.log("res", response.data.data)
					setUserData(response.data.data)
                    setSource(response.data.data.image)
					console.log("user", userData)
				} else {
					CisUI().Notification('error', response.data.msg);
				}
			})
			.catch(function (error) {
				console.log(error);
			})
	})
	useEffect(() => {
		getUserData()
	}, [])
	console.log("user", userData)
	// const getCountry = (() => {
	// 	axios.get(Config.apiUrl + "countries")
	// 		.then(function (response) {
	// 			setCountry(response.data.data)
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		})
	// })
	// useEffect(() => {
	// 	getCountry()
	// }, [])


	const onFinish = (values) => {
        const data = {
            ...values,
            image: image
        }
        console.log(data);

		axios.patch(Config.apiserver + "updatemyaccount", data, CisUI().HeaderRequest)
			.then(res => {
				console.log(res)
				if (res.data.status === 1) {
					CisUI().Notification('success', res.data.msg);
				} else {
					CisUI().Notification('error', res.data.msg);
				}
			})
			.catch(errors => {
				console.log(errors);
			});
	};
	const changeImage = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();

		reader.onload = (e) => {
			setImage({
				image: e.target.result
			});
		};
		// console.log(source2)
		reader.readAsDataURL(file);
	}


	return (
		<Card title={<IntlMessages id={`Profile Settings`}/>}>
			{CisUI().showLoading}
			{
				userData != '' &&
				(
					<Form onFinish={onFinish}
					      form={form}
					      {...CisUI().formItemLayout}
					      className="ant-advanced-search-form"
					      initialValues={{
						      address: userData.address,
						      city: userData.city,
						      country: userData.country,
						      email: userData.email,
						      first_name: userData.firstname,
						      gender: userData.gender?.toString(),
						      image: userData.image,
						      last_name: userData.lastname,
						      phone: userData.phone,
						      // ssn_no: userData.ssn_no,
					      }}
					>
						<Row className="ant-row gx-mr-4"
						     style={{rowGap: "15px"}}
						>
							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label={<IntlMessages id={`First Name`}/>}
									rules={[
										{required: true, message: "First Name is required"},]} name="first_name">
									<Input placeholder="first name"/>
								</Form.Item>
							</Col>

							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label={<IntlMessages id={`Last Name`}/>}
									rules={[
										{required: true, message: "Last Name is required"},
									]}
									name="last_name"
								>
									<Input placeholder="last name"/>
								</Form.Item>
							</Col>

							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label={<IntlMessages id={`Email`}/>}
									rules={[
										{required: true, message: "Email is required"},
									]}
									name="email"
								>
									<Input placeholder="Email"/>
								</Form.Item>
							</Col>


							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label={<IntlMessages id={`Phone`}/>}
									rules={[
										{required: true, message: "Phone is required"},
									]}
									name="phone"
								>
									<Input placeholder="Phone"/>
								</Form.Item>
							</Col>

							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label={<IntlMessages id={`Gender`}/>}
									rules={[
										{
											required: true,
											message: "Gender is required",
										},
									]}
									name="gender"
								>
									<Select
										placeholder="Select An Option"
										style={{width: "100%"}}
										name="status"
									>
										<Option value="Male">Male</Option>
										<Option value="Female">Female</Option>
										<Option value="Other">Other</Option>
									</Select>
								</Form.Item>
							</Col>

							{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
							{/*	<Form.Item*/}
							{/*		label={<IntlMessages id={`SSN No.`}/>}*/}
							{/*		rules={[*/}
							{/*			{required: true, message: "SSN No is required"},*/}
							{/*		]}*/}
							{/*		name="ssn_no"*/}
							{/*	>*/}
							{/*		<Input placeholder="ssn no"/>*/}
							{/*	</Form.Item>*/}
							{/*</Col>*/}

							{/*<Col lg={8} md={8} sm={12} xs={24}>*/}
							{/*	<Form.Item*/}
							{/*		label={<IntlMessages id={`Country`}/>}*/}
							{/*		rules={[*/}
							{/*			{required: true, message: "Country is required"},*/}
							{/*		]}*/}
							{/*		name="country"*/}

							{/*	>*/}
							{/*		<Select*/}
							{/*			placeholder="Select Your Country"*/}
							{/*			style={{width: "100%"}}*/}
							{/*		>*/}
							{/*			{countries.map((country, index) => (*/}
							{/*				<Option key={++index} value={country.id}>*/}
							{/*					{country.name}*/}
							{/*				</Option>*/}
							{/*			))}*/}
							{/*		</Select>*/}
							{/*	</Form.Item>*/}
							{/*</Col>*/}

							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label={<IntlMessages id={`City`}/>}
									rules={[
										{required: true, message: "City is required"},
									]}
									name="city"
								>
									<Input placeholder="city"/>
								</Form.Item>
							</Col>

							<Col lg={8} md={8} sm={12} xs={24}>
								<Form.Item
									label={<IntlMessages id={`Address`}/>}
									// rules={[
									//     {
									//         required: true,
									//         message: "Address is required",
									//     },
									// ]}
									name="address"
								>
									<Input placeholder="Address"/>
								</Form.Item>
							</Col>

							<Col lg={8} md={8} sm={12} xs={24}>

							  <Form.Item
							    label={<IntlMessages id={`Image`} />}
							    name="image"
							  >
							    {/*{*/}
							    {/*  (userData.image != null && image=="") &&*/}
							    {/*  imageSet()*/}
							    {/*}*/}
							    <input name="image" type="file"  onChange={changeImage}/>
							    {/*{ console.log("img",source,source2)}*/}
							    {source !== "" &&
							      <img src={`https://panttenapi.datalibrary.io/public/upload/profile/${source}`} alt="Image"
                                       style={{width: "120px", height: "100px", marginTop: "10px"}}/>
							    }
							  </Form.Item>
							</Col>


							<Col lg={24} xs={24} style={{textAlign: "center"}}>
								{/*<Button type="primary" htmlType="submit">*/}
								{/*	Save*/}
								{/*</Button>*/}
								<Button type="primary" htmlType="submit">
									Save
								</Button>
							</Col>
						</Row>
					</Form>
				)
			}
		</Card>
	);
};

export default ProfileSettings;
