import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import axios from "axios";
import Config from "../../util/config";
import {CisUI} from "../../util/CISUI";
import IntlMessages from "../../util/IntlMessages";
import {Link} from "react-router-dom";

const UserInfo = () => {
  const [image, setImage] = useState("");

  const dispatch = useDispatch();
    console.log("image", image)
    const getUserData = (() => {
        axios.get(Config.apiserver + "myaccount", CisUI().HeaderRequest)
            .then(function (response) {
                console.log("response", response)
                if (response.data.status === 1) {
                    console.log("res", response.data.data)
                    setImage(response.data.data.image)
                    console.log("user", image)
                } else {
                    CisUI().Notification('error', response.data.msg);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    })
    useEffect(() => {
        getUserData()
    }, [])

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/settings/profilesettings"><IntlMessages id="My Account"/></Link></li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
        { (image !== "null") ?
            (
                <img src={`https://panttenapi.datalibrary.io/public/upload/profile/${image}` } alt="Image"
                     style={{width: "80px", height: "80px"}} className="gx-size-40 gx-pointer gx-mr-3"/>
            )
            :
            (<Avatar src="/favicon.png" className="gx-size-40 gx-pointer gx-mr-3" alt=""/>)
        }
    </Popover>
  );
};

export default UserInfo;
